import React from "react";
import cx from "classnames";

import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";

import ScrollSection from "../components/scroll-section/scroll-section";
import { DocumentToReactComponents } from "../components/blog-elements/blog-elements";

class PageTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulPage");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const { seoTitle, seoDescription, subtitle, displayTitle, preTitle } = post;
    const content = post.content ? post.content : null;

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: seoTitle,
            seoDescription,
          }}
        />
        <ScrollSection>
          <Hero
            title={displayTitle.toUpperCase()}
            pretitle={{ title: preTitle.toUpperCase() }}
            subtitle={subtitle}
          />
        </ScrollSection>
        <ScrollSection>
          <div className={cx("mt-5 mx-auto mb-24 page-wrapper")}>
            <div className={cx("page-content")}>
              {content && DocumentToReactComponents(content, {})}
            </div>
          </div>
        </ScrollSection>
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      displayTitle
      preTitle
      seoTitle
      seoDescription
      subtitle
      slug
      content {
        raw
        references {
          title
          description
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1920
            )
            description
          }
        }
      }
    }
  }
`;
